import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Wrapper from './Wrapper';
import DownloadBadge from '../DownloadBadge';
import './index.css';

class HowToUseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      system: 'excel',
    };
  }

  onChangeSystem = (system) => {
    this.setState({ system });
  }

  genImage = (images, style = {}) => (
    <div className="columns">
      {images.map(image => (
        <div className="column" key={image}>
          <Img fluid={image.childImageSharp.fluid} style={style} />
        </div>
      ))}
    </div>
  )

  render() {
    const { system } = this.state;
    const { locale, pageContext, config } = this.props;
    const { images, introId } = config;
    return (
      <Wrapper pageContext={pageContext} locale={locale}>
        <div className="center">
          <h1 className="product-name is-size-2 mb4">
            <FormattedMessage id={introId.title} />
          </h1>
          <div className="tabs is-toggle">
            <ul style={{ justifyContent: 'center' }}>
              <li
                className={system === 'excel' ? 'is-active' : ''}
                onClick={() => this.onChangeSystem('excel')}
              >
                <a>
                  <span className="icon is-small">
                    <i className="far fa-file-excel" aria-hidden="true" />
                  </span>
                  <span>Excel</span>
                </a>
              </li>
              <li
                className={system === 'google' ? 'is-active' : ''}
                onClick={() => this.onChangeSystem('google')}
              >
                <a>
                  <span className="icon is-small">
                    <i className="fab fa-google" aria-hidden="true" />
                  </span>
                  <span>Spreadsheet</span>
                </a>
              </li>
              <li
                className={system === 'numbers' ? 'is-active' : ''}
                onClick={() => this.onChangeSystem('numbers')}
              >
                <a>
                  <span className="icon is-small">
                    <i className="fab fa-apple" aria-hidden="true" />
                  </span>
                  <span>Numbers</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="container">
            <div className="content">
              <div className="style-block">
                <p className="is-size-5">
                  1.
                  {' '}
                  <FormattedMessage id={introId.step1} />
                </p>
                {this.genImage(images[system].intro1, { maxWidth: '500px', margin: '0 auto' })}
              </div>
              <div className="style-block">
                <p className="is-size-5 mb3">
                  2.
                  {' '}
                  <FormattedMessage id={introId.step2} />
                </p>
                {this.genImage(images[system].intro2, { maxWidth: '300px', margin: '0 auto' })}
              </div>
              <div className="style-block">
                <p className="is-size-5 mb3">
                  3.
                  {' '}
                  <FormattedMessage id={introId.step3} />
                </p>
                {this.genImage(images[system].intro3, { maxWidth: '700px', margin: '0 auto' })}
              </div>

              <p className="is-size-6 has-text-centered mb3 mt4">
                <FormattedMessage
                  id="intro.congrat"
                  defaultMessage="Congrats! You can start to customize your font style collection."
                />
              </p>
            </div>
          </div>
          <DownloadBadge />
        </div>
      </Wrapper>
    );
  }
}

export default HowToUseTable;
