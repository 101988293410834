import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import HowToTableUse from '../../components/HowToUse/Table';
import { genHowToUseBreadcrumbJsonLd } from '../../utils/jsonld';

const meta = {
  en: {
    title: 'Transform Excel to Evernote table - EverTool',
    subtitle: 'Transform Excel, Google spreadsheet, Numbers to Evernote table',
  },
  'zh-tw': {
    title: '將 Excel 轉換為 Evernote 表格 - EverTool',
    subtitle: '將 Excel, Google 表單, Numbers 的表格轉換為 Evernote 表格',
  },
  'zh-cn': {
    title: '将 Excel 转换为印象笔记表格 - EverTool',
    subtitle: '將 Excel, Google 表單, Numbers 的表格轉換為印象笔记表格',
  },
  ja: {
    title: 'EvernoteのテーブルにExcelを変換 - EverTool',
    subtitle: 'Evernoteのテーブルにエクセルは、Googleスプレッドシート、数字を変換',
  },
};


class ThreeStepsToGetStartedPage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;

    const config = {
      images: {
        excel: {
          intro1: [data.excel],
          intro2: [data.step2],
          intro3: [data.step3],
        },
        google: {
          intro1: [data.google],
          intro2: [data.step2],
          intro3: [data.step3],
        },
        numbers: {
          intro1: [data.numbers],
          intro2: [data.step2],
          intro3: [data.step3],
        },
      },
      introId: {
        title: 'table.title',
        step1: 'table.step1',
        step2: 'table.step2',
        step3: 'table.step3',
      },
    };
    this.state = { config };
  }

  render() {
    const { pageContext } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;
    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
          jsonld: genHowToUseBreadcrumbJsonLd(meta[locale].title, url, locale),
        }}
      >
        <div className="how-to-wrapper">
          <p className="center">Release in version 0.3.0 and later</p>
          <HowToTableUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
            oneSystem="mac"
          />
        </div>
      </Layout>
    );
  }
}

export default ThreeStepsToGetStartedPage;

export const pageQuery = graphql`
  query HowToTableQuery {
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
    excel: file(relativePath: { eq: "how-to/table/excel.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    google: file(relativePath: { eq: "how-to/table/google.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    numbers: file(relativePath: { eq: "how-to/table/numbers.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step2: file(relativePath: { eq: "how-to/table/step2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step3: file(relativePath: { eq: "how-to/table/excel.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
